<template>
  <Pie
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Pie } from "vue-chartjs/legacy";

import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "PieChart",
  components: {
    Pie,
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    let role = sessionStorage.getItem("role");
    if (role == 13) {
      this.chartData = {
        labels: ["ABI", "ABI2"],
        datasets: [
          {
            backgroundColor: ["#C49732", "#1E354A"],
            data: [10, 20],
          },
        ],
      };
    } else {
      this.chartData = {
        labels: ["ABI-CIMB", "ABI-BAGI"],
        datasets: [
          {
            backgroundColor: ["#ca1214", "#8b734a"],
            data: [40, 20],
          },
        ],
      };
    }
  },
};
</script>
