<template>
    <!-- <div class="container"> -->
    <div class="wrap-chart">
        <div class="aseets">
            <p>Assets Under Management</p>
            <PieChart />
        </div>
        <div class="turn-over">
            <p>Trading Turn Over</p>
            <BarChart />
        </div>
        <div class="revenue">
            <p>Revenue</p>
            <BarHorizontal />
        </div>
        <div class="performa">
            <p>Performance</p>
            <LineChart />
        </div>
        <div class="rped">
            <p>Number Of Account</p>
            <line-ca />
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
import LineChart from "./LineChart.vue";
import PieChart from "./PieChart.vue";
import BarChart from "./BarChart.vue";
import BarHorizontal from "./BarHorizontal.vue";
import LineCa from "./LineCa.vue";

import Chart from "chart.js";

export default {
    components: {
        LineChart,
        LineCa,
        PieChart,
        BarChart,
        BarHorizontal,
    },
    data() {
        return {
            chartdata: null,
            myChartLine: null,
            myChartBar: null,
            myChartHorizontalBar: null,
            myChartPie: null,
            role: "",
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
        };
    },
    mounted() {
        // let chartlist = ["19", "10"];
        // this.chartdata = chartlist;
        this.role = sessionStorage.getItem("role");
        // this.createChartLine();
        // this.createChartBar();
        // this.createChartPie();
        // this.createChartHorizontalBar();
    },
    methods: {
        createChartLine() {
            // console.log(chartId,chartData, chartLabel)
            const ctx = document.getElementById("lineChart").getContext("2d");
            // Save reference
            this.myChartLine = new Chart(ctx, {
                type: "line",
                data: {
                    labels: ["KBI", "Bank"],
                    // labels: chartLabel,
                    datasets: [
                        // {
                        //   label: "",
                        //   borderColor: "#284184",
                        //   pointBackgroundColor: "#284184",
                        //   lineTension: 0.1,
                        //   backgroundColor: "rgba(0, 0, 0, 0)",
                        //   data: chartData,
                        // },
                        {
                            label: "Number of Account",
                            borderColor: "rgb(75, 192, 192)",
                            fill: false,
                            data: [10, 30],
                        },
                        // {
                        //   label: "Trading Turn Over",
                        //   borderColor: "rgba(54, 162, 235)",
                        //   fill: false,
                        //   data: [10, 20],
                        // },
                        // {
                        //   label: "Revenue",
                        //   borderColor: "rgba(255, 99, 132)",
                        //   fill: false,
                        //   data: [40, 20],
                        // },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: !this.isMobile,
                    legend: {
                        position: "bottom",
                        display: false,
                    },
                    tooltips: {
                        mode: "label",
                        xPadding: 10,
                        yPadding: 10,
                        bodySpacing: 10,
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    min: 0,
                                },
                            },
                        ],
                    },
                },
            });
        },
        createChartBar() {
            // console.log(chartId,chartData, chartLabel)
            const ctx = document.getElementById("chartBar").getContext("2d");
            // Save reference
            this.myChartLine = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: ["January", "February", "March"],
                    // labels: chartLabel,
                    datasets: [
                        // {
                        //   label: "",
                        //   borderColor: "#284184",
                        //   pointBackgroundColor: "#284184",
                        //   lineTension: 0.1,
                        //   backgroundColor: "rgba(0, 0, 0, 0)",
                        //   data: chartData,
                        // },
                        {
                            label: "Asset Under Managemen",
                            borderColor: "rgb(75, 192, 192)",
                            fill: false,
                            data: [10, 30, 10],
                        },
                        {
                            label: "Trading Turn Over",
                            borderColor: "rgba(54, 162, 235)",
                            fill: false,
                            data: [10, 20, 50],
                        },
                        {
                            label: "Revenue",
                            borderColor: "rgba(255, 99, 132)",
                            fill: false,
                            data: [40, 20, 10],
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: !this.isMobile,
                    legend: {
                        position: "bottom",
                        display: false,
                    },
                    tooltips: {
                        mode: "label",
                        xPadding: 10,
                        yPadding: 10,
                        bodySpacing: 10,
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    min: 0,
                                },
                            },
                        ],
                    },
                },
            });
        },
        createChartPie() {
            // console.log(chartId,chartData, chartLabel)
            const ctx = document.getElementById("pieChart").getContext("2d");
            // Save reference
            this.myChartLine = new Chart(ctx, {
                type: "pie",
                data: {
                    labels: ["January", "February", "March"],
                    // labels: chartLabel,
                    datasets: [
                        // {
                        //   label: "",
                        //   borderColor: "#284184",
                        //   pointBackgroundColor: "#284184",
                        //   lineTension: 0.1,
                        //   backgroundColor: "rgba(0, 0, 0, 0)",
                        //   data: chartData,
                        // },
                        {
                            label: "Asset Under Managemen",
                            borderColor: "rgb(75, 192, 192)",
                            fill: false,
                            data: [10, 30, 10],
                        },
                        {
                            label: "Trading Turn Over",
                            borderColor: "rgba(54, 162, 235)",
                            fill: false,
                            data: [10, 20, 50],
                        },
                        {
                            label: "Revenue",
                            borderColor: "rgba(255, 99, 132)",
                            fill: false,
                            data: [40, 20, 10],
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: !this.isMobile,
                    legend: {
                        position: "bottom",
                        display: false,
                    },
                    tooltips: {
                        mode: "label",
                        xPadding: 10,
                        yPadding: 10,
                        bodySpacing: 10,
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    min: 0,
                                },
                            },
                        ],
                    },
                },
            });
        },
        createChartHorizontalBar() {
            // console.log(chartId,chartData, chartLabel)
            const ctx = document.getElementById("chartBar ").getContext("2d");
            // Save reference
            this.myChartLine = new Chart(ctx, {
                type: "Bar",
                data: {
                    labels: ["KBI", "BANK"],
                    // labels: chartLabel,
                    datasets: [
                        // {
                        //   label: "",
                        //   borderColor: "#284184",
                        //   pointBackgroundColor: "#284184",
                        //   lineTension: 0.1,
                        //   backgroundColor: "rgba(0, 0, 0, 0)",
                        //   data: chartData,
                        // },
                        {
                            label: "Number of Account",
                            borderColor: "rgb(75, 192, 192)",
                            fill: false,
                            data: [10, 30],
                        },
                    ],
                },
                options: {
                    responsive: true,
                    maintainAspectRatio: !this.isMobile,
                    legend: {
                        position: "bottom",
                        display: false,
                    },
                    tooltips: {
                        mode: "label",
                        xPadding: 10,
                        yPadding: 10,
                        bodySpacing: 10,
                    },
                    scales: {
                        yAxes: [
                            {
                                ticks: {
                                    beginAtZero: true,
                                    min: 0,
                                },
                            },
                        ],
                    },
                },
            });
        },
    },
};
</script>
<style scoped>
.title-page {
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 40px;
}
.wrap-chart {
    display: flex;
    gap: 1.34444rem;
    flex-wrap: wrap;
    justify-content: space-between;
}

.aseets,
.turn-over,
.revenue,
.performa,
.rped {
    box-shadow: 0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%);
    padding: 1rem;
    width: 49%;
    border-radius: 5px;
}

.top-title {
    display: flex;
    justify-content: space-between;
}

.bagi {
    height: 30px;
}
</style>
